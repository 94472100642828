import { render, staticRenderFns } from "./MeusBoletos.vue?vue&type=template&id=435f6320&scoped=true"
import script from "./MeusBoletos.vue?vue&type=script&lang=js"
export * from "./MeusBoletos.vue?vue&type=script&lang=js"
import style0 from "./MeusBoletos.vue?vue&type=style&index=0&id=435f6320&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "435f6320",
  null
  
)

export default component.exports