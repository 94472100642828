<template>
    <div
        v-if="loading"
        style="width:100%;text-align:center"
    >
       <img src="/img/loading.gif" style="max-height:35px"/>
    </div>
</template>

<script>
export default {
    name: "ProgressLoading",
    props: {
        loading: {
                type: Boolean
        }
    },
    methods: {
        setLoading() {
            this.$emit("setLoading", false);
        },
    },
}
</script>