// global.js

// Função para inicializar dados globais
export function initializeGlobals() {
    const pagesNonLogged = [
        'Login',
        'home',
        'possuo-o-codigo',
        'codigo-enviado',
        'receber-codigo',
    ];

    // Obtenha userData do localStorage
    const userData = JSON.parse(localStorage.getItem("userData"));
    // console.log('userData GLOBAL.JS: ' + JSON.stringify(userData));
  
    const customData = {
      page: {
        name: userData.pageName,
      },
      site: {
        versao: "1.0.0",
        channel: 'portal-de-negociacao',
      },
      user: {
        id: userData.id ? userData.cpfCnpj : undefined,
        logged: pagesNonLogged.includes(userData.pageName) ? false : true,
      },
      page_location: userData.page_location,
    };

    // console.log('customDataXXX GLOBAL.JS: ' + JSON.stringify(customData));
  
    return {
      userData,
      customData,
    };
  }
  